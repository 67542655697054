<template>

    <div>

        <v-container fluid>

            <v-form ref="form">
                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Present Address</v-subheader>
                </v-row>

                <v-row align="center">
                    <v-autocomplete 
                        autocomplete="province"
                        ref="province"
                        :rules="[rules.required]"
                        :loading="province_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="province" 
                        :items="province_options" 
                        :search-input.sync="search_province"
                        item-text="Title" 
                        item-value="MappingId" 
                        label="Province"
                        @change="provinceOnChange()"
                        >
                    
                    </v-autocomplete>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        autocomplete="city"
                        ref="city"
                        :rules="[rules.required]"
                        :loading="city_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="city" 
                        :items="city_options" 
                        :search-input.sync="search_city"
                        item-text="Title" 
                        item-value="MappingId" 
                        label="City"
                        :disabled="disable_city"
                        @change="cityOnChange()"
                        >
                    
                    </v-autocomplete>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        autocomplete="barangay"
                        ref="barangay"
                        :rules="[rules.required]"
                        :loading="barangay_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="barangay" 
                        :items="barangay_options" 
                        :search-input.sync="search_barangay"
                        item-text="Title" 
                        item-value="SysId" 
                        label="Barangay"
                        :disabled="disable_barangay">
                    
                    </v-autocomplete>
                </v-row>

                <v-row align="center">
                    <v-text-field
                        ref="street"
                        v-model="street"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        label="Street"
                        required>
                    </v-text-field>
                </v-row>

            </v-form>
        </v-container>
      
        <v-row  align="center" justify="space-around" class="bottom-nav">
            <v-col class="bottom-nav-item">
                <v-btn @click="handleBack()" depressed class="bottom-nav-btn back-btn">
                    Back
                </v-btn>
            </v-col>
            <v-col class="bottom-nav-item">
                <v-btn @click="handleNext()" depressed class="bottom-nav-btn">
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { getLookupValues } from '@/api/profile'
import { mapGetters } from 'vuex'

export default {
    computed: {

        ...mapGetters([
            'address',
        ]),
    },

    beforeRouteLeave(to, from, next) {

        if(to.name == 'Calculator' || to.name == 'Profile'){
            var addressFormIsValid = this.$refs.form.validate()
            this.$store.dispatch('user/addressFormIsValid', addressFormIsValid)
            next()
        } else {

            if(this.$refs.form.validate()){
                var data = {
                    province: this.province,
                    search_province: this.search_province,
                    province_options: this.province_options,
                    city: this.city,
                    search_city: this.search_city,
                    city_options: this.city_options,
                    disable_city: this.disable_city,
                    barangay: this.barangay,
                    search_barangay: this.search_barangay,
                    barangay_options: this.barangay_options,
                    disable_barangay: this.disable_barangay,
                    street: this.street,
                    province_loading: this.province_loading,
                    city_loading: this.city_loading,
                    barangay_loading: this.barangay_loading,
                }
                
                this.$store.dispatch('user/address', data)
                this.$store.dispatch('user/addressFormIsValid', true)

                next();

            } 
        } 
      
    },
  
    data: function() {
        return {
            province: null,
            search_province: null,
            province_options: [],
            city: null,
            search_city: null,
            city_options: [],
            disable_city: true,
            barangay: null,
            search_barangay: null,
            barangay_options: [],
            disable_barangay: true,
            street: '',

            province_loading: true,
            city_loading: false,
            barangay_loading: false,

            //rules
            rules: {
                required: v => !!v || 'Required.',
            },
        }
    },

    methods:{
        handleBack(){

            this.$router.push({ path: '/profile/index' })

        },
        handleNext(){

            this.$router.push({ path: '/attachment/index' })
        },

        fetchLookupValues(value, id=''){
            return getLookupValues(value, id).then(res => {
                return res.data.data
                
            })
        },

        provinceOnChange(){

            if(this.province) {
                this.disable_city = false
                this.city = null
                this.$refs.city.reset()
                this.city_loading = true
                this.fetchLookupValues('city', this.province).then(res => {
                    var cities = res
                    cities.sort((a,b) => (a.Title > b.Title) ? 1 : ((b.Title > a.Title) ? -1 : 0)); // sort by name
                    this.city_options = cities
                    this.city_loading = false
                })
            } else {

                this.disable_city = true
                this.city = null
                this.city_options = []

            }
            // disable barangay on province change
            this.disable_barangay = true
            this.barangay = null
            this.barangay_options = []

        },

        cityOnChange(){

            if(this.city) {
                this.disable_barangay = false
                this.barangay = null
                this.$refs.barangay.reset()
                this.barangay_loading = true
                this.fetchLookupValues('brgy', this.city).then(res => {
                    var barangays = res
                    barangays.sort((a,b) => (a.Title > b.Title) ? 1 : ((b.Title > a.Title) ? -1 : 0)); // sort by name
                    this.barangay_options = barangays
                    this.barangay_loading = false
                })
            } else {

                this.disable_barangay = true
                this.barangay = null
                this.barangay_options = []

            }

        },
      
    },
    created() {

        this.fetchLookupValues('province').then(res => {
            var provinces = res
            provinces.sort((a,b) => (a.Title > b.Title) ? 1 : ((b.Title > a.Title) ? -1 : 0)); // sort by name

            this.province_options = provinces
            this.province_loading = false
        })
    },

}
</script>